.owl-carousel .item{
	margin-left:30px;
}

.owl-carousel {
	list-style: none;
	margin: 0;
	margin-left:-15px;
}

.owl-carousel .owl-wrapper-outer{
	width:101%!important;
}
.owl-carousel.single-carousel .owl-wrapper-outer{
	width:100%!important;
}

.owl-theme .owl-nav>div {
	display: none!important;
}

.owl-theme .owl-nav div.owl-prev{
	left:30px;
}
.owl-theme .owl-nav div.owl-next{
	right:0;
}

.owl-theme:hover .owl-nav div{
	display:block!important;
}


.carousel-fw.owl-theme .owl-nav{
	width:100%;
}