.who-right {
    height: unset;
    background-color: #f5f5f5;
    @include clearfix;
}

.who-right-content {
    background-color: unset;
    height: unset;
    @media (min-width: 990px) {
        padding-top: 12px;
    }
}

#header {

    .header-container {
        text-align: center;
        @media (min-width: 991px) {
            text-align: left;
        }
    }
    
    .header-column {
        &:first-child {
            @media (min-width: 991px) {
                width: 45%;
                text-align: center;
            }
            @media (min-width: 1200px) {
                width: 50%;
                text-align: left;
            }
        }
    }

    .header-logo {

        @include clearfix;
        margin-bottom: 20px;
        padding-top: 12px; 

        @media (min-width: 667px) {
            width: auto;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }

        img {
            @media (min-width: 667px) {
                display: inline-block;
            }
            @media (min-width: 991px) {
                margin-bottom: 0;
            }
        }
        
        .logo-text {
            @media (min-width: 667px) {
                float: unset;
                display: inline-block;
                vertical-align: middle;
            }
    
            .primary-text {
                line-height: 1.3;
            }
    
        }

    }

}

.footer-logo-link {
    @include clearfix;
    display: block;
    margin-bottom: 12px;
    .logo-text {
        line-height: 1.3;
    }
}

.sub-menu {
    ul {
        list-style: none;
        padding-left: 0;

        a {
            font-size: 18px;
        }
    }
}

.grav-lightslider .lSSlideOuter .lSPager.lSpg {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

@media (max-width: 667px) {
    .lSSlideOuter .panel-bg .panel-padding {
        padding: 40px 10px !important;
    }
    .slide-title {
        font-size: 30px;
        line-height: 1.2;
    }
    .slide-subtitle {
        font-size:20px;
        line-height: 1.3;
    }
    
}